@import 'assets/scss/variables';

h1 {
  margin-bottom: $spacing-large;
}

.user-selector {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;

  .user {
    flex: 0 1 33%;
    button {
      min-width: 150px;
      min-height: 150px;
    }
  }
}

li {
  list-style: none;
  margin-bottom: $spacing-small;
}

.map-button {
  margin-left: $spacing-regular;
}

.user-access-allocation-modal {
  .modal-footer {
    justify-content: space-between;
    .modal-action-buttons {
      justify-content: flex-end;
      .btn-secondary {
        margin-right: $spacing-regular;
      }
    }
  }
}

#user-access-to-budgets {
  h1 {
    font-size: $spacing-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $spacing-large;
  }

  .users-with-no-access {
    input,
    select {
      font-size: 13px;
    }
  }

  .users-with-no-access {
    padding-bottom: $spacing-regular;
    margin-bottom: $spacing-large;

    .user-access-table {
      padding: $spacing-regular $spacing-large;
      margin-bottom: $spacing-large;

      .user-access-table-header {
        border-bottom: 1px solid;
        font-weight: $font-weight-bold;
      }

      .user-access-table-body {
        padding: $spacing-large 0px 0px;

        .add-more-icon,
        .remove-icon {
          right: $spacing-xl;
          width: $spacing-xl;
          height: $spacing-xl;
          color: $blue-100;
        }

        .add-more-icon {
          position: relative;
          bottom: -$spacing-xxl;
        }
      }

      .user-access-table-buttons {
        padding-top: $spacing-large;
      }
    }
  }
}
