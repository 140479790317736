@import 'assets/scss/variables';

.admin-page {
  h1 {
    font-size: $spacing-large;
    font-weight: $font-weight-bolder;
    margin-bottom: $spacing-large;
  }

  input,
  select {
    font-size: 13px;
    padding: 0.5rem 0.75rem;
  }

  .react-select {
    font-size: 13px;
  }

  label {
    font-weight: 700;
    text-wrap: nowrap;
  }

  table {
    font-size: 12px;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;

    thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: 0 4px 15px -2px rgba(0, 0, 0, 0.15);
    }

    th {
      font-weight: 500;
    }

    th button.sorting-button {
      width: 100%;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      outline: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    th button.sorting-button svg {
      color: #b4b4b4;
    }

    td button {
      border: 0;
      outline: 0;
      font-size: 12px;
    }
  }
}

.update-role-modal {
  font-size: 12px;
  .h4,
  button,
  input {
    font-size: 14px;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #fff;
    border-color: #fff;
  }
}

.card-transparent {
  border-radius: $spacing-regular;
  border: 1px solid $gray-80;
  padding: $spacing-regular;
}
