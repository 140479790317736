@import '../../../assets/scss/variables.scss';

.credit-management {
  .add-more-icon,
  .remove-icon {
    font-size: 16px;
    color: $blue-100;
  }

  .remove-icon {
    color: red;
  }
}

.credit-form {
  display: flex;
  justify-content: space-around;
  gap: 10px;

  label {
    font-size: $font-size-regular;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .field {
    flex-shrink: 0;
    flex-grow: 1;
  }
}
