.vendor-program {
  font-size: 12px;
  .input-search {
    font-size: 12px;
  }

  .btn-detail,
  .btn-remap {
    font-size: 10px;
  }

  .pagination-controls {
    select,
    button {
      font-size: 12px;
    }
  }

  .pagination-controls button {
    width: 32px;
    height: 32px;
  }

  .program-form {
    label.form-label {
      display: block;
    }
  }
}
