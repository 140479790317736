:root {
  --rts-primary-color: #6283ba;
  --rts-gray-color: #adb5bd;
  --rts-white-color: #fff;
}

.display___block {
  display: block;
}

.display____inline___block {
  display: inline-block;
}

.display___none {
  display: none !important;
}

// @media (max-width: 991.98px) {
//   .display___md___none {
//     display: none !important;
//   }
// }

.rts___tabs___container {
  display: flex;
  overflow: auto;
}

@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 5px;
  }
}

.rts___tabs {
  display: flex;
  overflow: auto;
  padding: 10px 0;
}

.rts___tabs.hide___rts___tabs___scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rts___tabs.hide___rts___tabs___scroll::-webkit-scrollbar {
  display: none;
}

.rts___tab {
  padding: 10px 40px;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  margin: 0 5px;
}

@media (max-width: 991.98px) {
  .rts___tab {
    margin: 0 2px;
  }
}

@media (max-width: 767.98px) {
  .rts___tab {
    padding: 5px 20px;
  }
}

.rts___nav___btn___container {
  display: flex;
}

.rts___btn {
  cursor: pointer;
  background-color: transparent;
  border-radius: 40px;
  border: 1px solid var(--rts-gray-color);
  font-size: 12px;
  color: #adb5bd;
}

.rts___tab___selected {
  background-color: var(--rts-primary-color);
  color: var(--rts-white-color);
  box-shadow: #00000029 0 1px 4px;
}

.rts___nav___btn {
  color: #0009;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rts___nav___btn:hover {
  background-color: var(--rts-primary-color);
  transition: 0.5s all;
}
.rts___nav___btn:hover > svg {
  stroke: var(--rts-white-color);
}
@media (max-width: 767.98px) {
  .rts___nav___btn > svg {
    width: 15px;
  }
}
.rts___svg___icon {
  stroke: var(--rts-gray-color);
}
button:disabled.rts___btn,
.rts___nav___btn:disabled,
button[disabled].rts___btn {
  cursor: not-allowed;
  color: var(--rts-gray-color);
  pointer-events: none;
}
.rts button:disabled.rts___btn svg,
.rts___nav___btn:disabled svg,
button[disabled].rts___btn svg {
  stroke: #0009;
}
